<template>
  <div id="stat" v-if="info">
    <a-row>
      <a-col :span="6">
        <div class="box">
          <h3>会员统计</h3>
          <p>会员总数:{{ info.user_count }}</p>
        </div>
      </a-col>
      <a-col :span="6">
        <div class="box">
          <h3>评论统计</h3>
          <p>评论总数:{{ info.comment_count }}</p>
        </div>
      </a-col>
    </a-row>

    <a-row>
      <a-col :span="6">
        <div class="box">
          <h3>文章统计</h3>
          <p>文章数量:{{ info.article_num.article_count }}</p>
          <p>文章点击量:{{ info.article_num.article_hit_count }}</p>
        </div>
      </a-col>
      <a-col :span="6">
        <div class="box">
          <h3>视频统计</h3>
          <p>视频数量:{{ info.video_num.video_count }}</p>
          <p>视频点击量:{{ info.video_num.video_hit_count }}</p>
        </div>
      </a-col>
      <a-col :span="6">
        <div class="box">
          <h3>
            病例统计
          </h3>
          <p>病例数量:{{ info.case_num.case_count }}</p>
          <p>病例点击量:{{ info.case_num.case_hit_count }}</p>
        </div>
      </a-col>
    </a-row>

    <a-row>
      <a-col :span="6">
        <div class="box" @click="goUser">
          <h3>新申请审核用户通知 <span class="s-icon" v-if="info.apply_count > 0">*</span></h3>
          <p>新用户申请数量:{{ info.apply_count }}</p>
        </div>
      </a-col>
      <a-col :span="6">
        <div class="box" @click="goDataAdministration">
          <h3>待审核资料 <span class="s-icon" v-if="info.material_check_count > 0">*</span></h3>
          <p>待审核资料数量:{{ info.material_check_count }}</p>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { statIndex } from "@/apis/index";
export default {
  data() {
    return {
      info: "",
    };
  },
  created() {
    document.title = `杏林医讯`
    statIndex().then((res) => {
      this.info = res.data;
      // console.log(res)
    });
  },
  methods: {
    goUser() {
      if (this.info.apply_count > 0) {
        this.$router.push({ path: "/users/user", query: { is_apply: 1, id: 3 } });
      }
    },
    goDataAdministration(){
      if(this.info.material_check_count>0){
        this.$router.push({ path: "/DataAdministration/CheckData", query: { status:1} });
      }
    }
  },
};
</script>

<style lang="less" scoped>
#stat {
  h3 {
    font-size: 26px;
    font-weight: bold;
  }

  .box {
    padding: 20px;
    background: #66BABC;
    width: 380px;
    margin-top: 20px;
    color: #fff;
    font-size: 18px;
    border-radius: 5px;
    cursor: default;

    .s-icon {
      color: red;
      animation: mydeamon 1s infinite ease-in-out;
      // font-size: 36px;
    }

    @keyframes mydeamon {
      0% {
        transform: scale(2); //scale代表闪烁的体型变化，其他倾斜等样式自行搜索
        opacity: 1; //透明程度
      }

      100% {
        transform: scale(1);
        opacity: 0.2;
      }
    }

  }

}
</style>
